"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formInit = formInit;
exports.postFormDataAsJson = postFormDataAsJson;
function formInit() {
    Array.from(document.getElementsByTagName("form")).forEach(async (form) => {
        form.onsubmit = async (event) => {
            form.classList.add('was-validated');
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
                return false;
            }
            // disable form buttons
            disableButtons(form);
            // hide any previous message
            let messageEl = document.getElementById('form-message');
            if (messageEl)
                messageEl.remove();
            event.preventDefault();
            const url = '/contact-submit';
            const formData = new FormData(form);
            submitForm(form, formData, url);
            // prevent reload
            return false;
        };
    });
}
async function submitForm(form, formData, url) {
    try {
        const responseData = await postFormDataAsJson(url, formData);
        handleResponse(responseData, form);
    }
    catch (error) {
        let div = document.createElement('div');
        div.className = 'alert bg-danger text-secondary shadow rounded p-3 mb-5';
        div.id = 'form-message';
        div.textContent = error.message;
        form.before(div);
        // form.remove()
        enableButtons(form);
    }
}
async function postFormDataAsJson(url, formData) {
    let plainFormData = Object.fromEntries(formData.entries());
    let formDataJsonString = JSON.stringify(plainFormData);
    console.log('formDataJsonString', formDataJsonString);
    let fetchOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: formDataJsonString,
    };
    let response = await fetch(url, fetchOptions);
    if (!response.ok) {
        let errorMessage = await response.text();
        throw new Error(errorMessage);
    }
    return response.json();
}
function handleResponse(responseData, form) {
    console.log('handleResponse');
    // add success message to replace form
    form.classList.add('form-success');
    let div = document.createElement('div');
    div.id = 'form-message';
    div.className = 'alert bg-tertiary text-primary shadow rounded p-3 mb-5';
    div.textContent = 'Thank you for taking the time to get in touch. We’ll get back to you as soon as possible.';
    form.before(div);
    form.classList.remove('was-validated');
    form.reset();
    // remove the form from the page, prevent resubmit with same nonce
    form.remove();
    enableButtons(form);
}
function disableButtons(form) {
    Array.from(form.getElementsByTagName('button')).forEach(button => {
        button.setAttribute('disabled', 'disabled');
    });
}
function enableButtons(form) {
    Array.from(form.getElementsByTagName('button')).forEach(button => {
        button.removeAttribute('disabled');
    });
}
const elements = document.querySelectorAll("INPUT, TEXTAREA");
//console.log(elements,'els')
for (var i = 0; i < elements.length; i++) {
    var el = elements[i];
    el.addEventListener('invalid', function (event) {
        var _a;
        var target = event.target;
        if (target.validity.patternMismatch && !target.validity.valueMissing) {
            (_a = target.parentElement) === null || _a === void 0 ? void 0 : _a.classList.add('invalid-pattern');
        }
    });
    el.addEventListener('change', function (event) {
        var _a, _b;
        var target = event.target;
        if (target.validity.patternMismatch && !target.validity.valueMissing) {
            (_a = target.parentElement) === null || _a === void 0 ? void 0 : _a.classList.add('invalid-pattern');
        }
        if (!target.validity.patternMismatch) {
            (_b = target.parentElement) === null || _b === void 0 ? void 0 : _b.classList.remove('invalid-pattern');
        }
    });
}
